<script>
//导入所有节点组件
import Approval from '../common/process/nodes/ApprovalNode.vue'
import Condition from '../common/process/nodes/ConditionNode.vue'
import Empty from '../common/process/nodes/EmptyNode.vue'
import Root from '../common/process/nodes/RootNode.vue'
import Node from '../common/process/nodes/Node.vue'
import { h, resolveComponent } from 'vue'
import DefaultProps from './DefaultNodeProps'
import End from '../common/process/nodes/EndNode.vue'

export default {
  name: 'ProcessTree',
  components: {
    Node,
    Root,
    Approval,
    Condition,
    Empty,
    End
  },
  data() {
    return {
      valid: true,
      conditionsList: []
    }
  },
  computed: {
    nodeMap() {
      return this.$store.state.process.nodeMap
    },
    dom() {
      return this.$store.state.process.design.process
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  render() {
    // console.log('渲染流程树')
    // console.log('this.dom', this.dom)
    this.nodeMap.clear()
    let processTrees = this.getDomTree(h, this.dom)
    // console.log(this.dom)
    return h('div', { class: { _root: true }, ref: '_root' }, processTrees)
  },
  methods: {
    getDomTree(h, node) {
      // console.log('getDomTree node', node)
      this.toMapping(node)
      if (this.isPrimaryNode(node)) {
        //普通业务节点
        let childDoms = this.getDomTree(h, node.children)
        this.decodeAppendDom(h, node, childDoms)
        return [h('div', { class: { 'primary-node': true } }, childDoms)]
      } else if (this.isBranchNode(node)) {
        // console.log(3333,node)
        let index = 0
        //遍历分支节点，包含并行及条件节点
        let branchItems = node.branchs.map(branchNode => {
          // console.log(branchNode.children.type);
          //处理每个分支内子节点
          this.toMapping(branchNode)
          let childDoms = this.getDomTree(h, branchNode.children)
          this.decodeAppendDom(h, branchNode, childDoms, { level: index + 1, size: node.branchs.length })
          //插入4条横线，遮挡掉条件节点左右半边线条
          this.insertCoverLine(h, index, childDoms, node.branchs)
          //遍历子分支尾部分支
          index++
          return h('div', { class: { 'branch-node-item': true } }, childDoms)
        })
        //插入添加分支/条件的按钮
        branchItems.unshift(
          h('div', { class: { 'add-branch-btn': true } }, [
            h(
              'div',
              {
                class: { 'add-branch-btn-el': true, 'btn-disabled': this.allDisabled },

                shape: 'round',
                onClick: () => this.addBranchNode(node),
                innerHTML: '添加条件'
              },
              []
            )
          ])
        )
        let bchDom = [h('div', { class: { 'branch-node': true } }, branchItems)]
        //继续遍历分支后的节点
        let afterChildDoms = this.getDomTree(h, node.children)
        return [h('div', {}, [bchDom, afterChildDoms])]
      } else if (this.isEmptyNode(node)) {
        // console.log(this.isEmptyNode(node))
        //空节点，存在于分支尾部
        let childDoms = this.getDomTree(h, node.children)
        this.decodeAppendDom(h, node, childDoms)
        return [h('div', { class: { 'empty-node': true } }, childDoms)]
      } else {
        // console.log('末端', node)
        return []
      }
    },
    //解码渲染的时候插入dom到同级
    decodeAppendDom(h, node, dom, props = {}) {
      props.config = node
      // console.log('node', node)
      // console.log('domdomdomdom111', dom)
      // console.log('组件名', node.type.toLowerCase())
      dom.unshift(
        h(resolveComponent(node.type.toLowerCase()), {
          ...props,
          ref: node.id,
          key: node.id,
          //定义事件，插入节点，删除节点，选中节点，复制/移动
          onInsertNode: type => this.insertNode(type, node),
          onDelNode: () => this.delNode(node),
          onSelected: () => this.selectNode(node)
        })
      )
      // console.log('domdomdomdom222', this.dom)
    },
    //id映射到map，用来向上遍历
    toMapping(node) {
      if (node && node.id) {
        this.nodeMap.set(node.id, node)
      }
    },
    insertCoverLine(h, index, doms, branchs) {
      if (index === 0) {
        //最左侧分支
        doms.unshift(h('div', { class: { 'line-top-left': true } }, []))
        doms.unshift(h('div', { class: { 'line-bot-left': true } }, []))
      } else if (index === branchs.length - 1) {
        //最右侧分支
        doms.unshift(h('div', { class: { 'line-top-right': true } }, []))
        doms.unshift(h('div', { class: { 'line-bot-right': true } }, []))
      }
    },

    //判断是否为主要业务节点
    isPrimaryNode(node) {
      // console.log('是否是主要业务节点',node);
      return node && (node.type === 'APPROVAL' || node.type === 'END' || node.type === 'ROOT')
    },
    isBranchNode(node) {
      return node && (node.type === 'CONDITIONS' || node.type === 'CONCURRENTS')
    },
    isEmptyNode(node) {
      return node && node.type === 'EMPTY'
    },
    //是分支节点
    isConditionNode(node) {
      return node.type === 'CONDITIONS'
    },
    //是分支节点
    isBranchSubNode(node) {
      return node && (node.type === 'CONDITION' || node.type === 'CONCURRENT')
    },
    // 随机id
    getRandomId() {
      return `node_${new Date()
        .getTime()
        .toString()
        .substring(5)}${Math.round(Math.random() * 9000 + 1000)}`
    },
    //选中一个节点
    selectNode(node) {
      this.$store.commit('selectedNode', node)
      this.$emit('selectedNode', node)
    },
    //处理节点插入逻辑
    insertNode(type, parentNode) {
      // console.log(type, '==-=-');
      this.$refs['_root'].click()
      //缓存一下后面的节点
      let afterNode = parentNode.children
      //插入新节点
      parentNode.children = {
        id: this.getRandomId(),
        parentId: parentNode.id,
        props: {},
        type: type
      }
      switch (type) {
        case 'APPROVAL':
          this.insertApprovalNode(parentNode, afterNode)
          break
        case 'CONDITIONS':
          this.insertConditionsNode(parentNode)
          break
       
        default:
          break
      }

      //拼接后续节点
      // console.log('parentNodeparentNode', parentNode)
      if (this.isBranchNode({ type: type })) {
        if (afterNode && afterNode.id) {
          afterNode.parentId = parentNode.children.children.id
        }
        parentNode.children.children.children = afterNode
      } else {
        if (afterNode && afterNode.id) {
          afterNode.parentId = parentNode.children.id
        }
        // console.log('afterNodeset', afterNode)
        parentNode.children.children = afterNode
      }
      // this.$forceUpdate()
    },
    // 审批props
    insertApprovalNode(parentNode) {
      parentNode.children.name = '审批'
      parentNode.children.props = this.$deepCopy(DefaultProps.APPROVAL_PROPS)
    },

    // 结束pros

    // 条件props
    insertConditionsNode(parentNode) {
      parentNode.children.name = '条件分支'
      parentNode.children.children = {
        id: this.getRandomId(),
        parentId: parentNode.children.id,
        type: 'EMPTY'
      }
      const idOne = this.getRandomId()
      const idTwo = this.getRandomId()
      parentNode.children.branchs = [
        {
          id: idOne === parentNode.children.id ? idOne + 1 : idOne,
          parentId: parentNode.children.id,
          type: 'CONDITION',
          props: this.$deepCopy(DefaultProps.CONDITION_PROPS),
          name: '条件1',
          typeElse: false,
          children: {
            id: this.getRandomId(),
            parentId: idOne === parentNode.children.id ? idOne + 1 : idOne,
            type: 'APPROVAL',
            name: '审批',
            desc: '任何人',
            props: this.$deepCopy(DefaultProps.APPROVAL_PROPS),
            children: {}
          }
        },
        {
          id: idTwo === parentNode.children.id ? idTwo + 1 : idTwo,
          parentId: parentNode.children.id,
          type: 'CONDITION',
          props: {
            // 默认条件不需要条件
            groupsType: 'OR',
            groups: [
              {
                groupType: 'AND',
                cids: [],
                conditions: []
              }
            ]
          },
          name: '默认条件',
          typeElse: true,
          children: {
            id: this.getRandomId(),
            parentId: idTwo === parentNode.children.id ? idTwo + 1 : idTwo,
            type: 'APPROVAL',
            name: '审批',
            desc: '任何人',
            props: this.$deepCopy(DefaultProps.APPROVAL_PROPS),
            children: {}
          }
        }
      ]
    },
    // 最后一个节点
    getBranchEndNode(conditionNode) {
      if (!conditionNode.children || !conditionNode.children.id) {
        return conditionNode
      }
      return this.getBranchEndNode(conditionNode.children)
    },

    // 添加条件
    addBranchNode(node) {
      // if (node.branchs.length < 8) {
      if (this.allDisabled) return
      let id = this.getRandomId()
      id = id === node.id ? id + 1 : id
      node.branchs.splice(node.branchs.length - 1, 0, {
        id: id,
        parentId: node.id,
        name: '条件' + node.branchs.length,
        props: this.isConditionNode(node) ? this.$deepCopy(DefaultProps.CONDITION_PROPS) : {},
        type: 'CONDITION',
        typeElse: false,
        children: {
          id: this.getRandomId(),
          parentId: id,
          type: 'APPROVAL',
          name: '审批',
          desc: '任何人',
          props: this.$deepCopy(DefaultProps.APPROVAL_PROPS),
          children: {}
        }
      })
    },
    //删除当前节点
    delNode(node) {
      console.log('删除节点', node)
      //获取该节点的父节点
      let parentNode = this.nodeMap.get(node.parentId)
      console.log('parentNode', parentNode)
      if (parentNode) {
        //判断该节点的父节点是不是分支节点
        if (this.isBranchNode(parentNode)) {
          console.log(111111)
          //移除该分支
          parentNode.branchs.splice(parentNode.branchs.indexOf(node), 1)
          //处理只剩1个分支的情况
          if (parentNode.branchs.length < 2) {
            //获取条件组的父节点
            let ppNode = this.nodeMap.get(parentNode.parentId)
            //判断唯一分支是否存在业务节点
            if (parentNode.branchs[0].children && parentNode.branchs[0].children.id) {
              //将剩下的唯一分支头部合并到主干
              ppNode.children = parentNode.branchs[0].children
              ppNode.children.parentId = ppNode.id
              //搜索唯一分支末端最后一个节点
              let endNode = this.getBranchEndNode(parentNode.branchs[0])
              //后续节点进行拼接, 这里要取EMPTY后的节点
              endNode.children = parentNode.children.children
              if (endNode.children && endNode.children.id) {
                endNode.children.parentId = endNode.id
              }
            } else {
              //直接合并分支后面的节点，这里要取EMPTY后的节点
              ppNode.children = parentNode.children.children
              if (ppNode.children && ppNode.children.id) {
                ppNode.children.parentId = ppNode.id
              }
            }
          }
        } else {
          //不是的话就直接删除
          if (node.children && node.children.id) {
            node.children.parentId = parentNode.id
          }
          parentNode.children = node.children
        }
        this.$forceUpdate()
      } else {
        this.$message.warning('出现错误，找不到上级节点😥')
      }
    },
    // 校验流程节点
    validateProcess() {
      let typeList = []
      this.valid = true
      let err = []
      this.validate(err, this.dom)

      //  至少设置一个审批
      this.branchValidate(this.dom, typeList)
      console.log(22222, typeList)

      const type = typeList.find(item => item === 'APPROVAL')
      if (!type) {
        // 主分支上没有审批节点 往下面找是否有审批
        if (this.dom.children.type === 'END') {
          err.push('每条分支上至少设置一个审批节点')
        } else {
          this.checkNode(this.dom, err)
        }
      }
      return err
    },
    validateNode(err, node) {
      if (this.$refs[node.id].validate) {
        this.valid = this.$refs[node.id].validate(err)
      }
    },
    //更新指定节点的dom
    nodeDomUpdate(node) {
      this.$refs[node.id].$forceUpdate()
    },

    //校验所有节点设置
    validate(err, node) {
      console.log('这是校验', node)
      if (this.isPrimaryNode(node)) {
        this.validateNode(err, node)
        this.validate(err, node.children)
      } else if (this.isBranchNode(node)) {
        //校验每个分支
        node.branchs.map(branchNode => {
          //校验条件节点
          this.validateNode(err, branchNode)
          //校验条件节点后面的节点
          this.validate(err, branchNode.children)
        })
        this.validate(err, node.children)
      } else if (this.isEmptyNode(node)) {
        this.validate(err, node.children)
      } else {
        console.log('012832749')
      }
    },

    // 主分支没有审批节点时校验条件分支流程里是否有审批人
    branchValidate(node, typeList) {
      if (node.type && node.children) {
        typeList.push(node.type)
        this.branchValidate(node.children, typeList)
      } else {
        console.log(2222, typeList)
      }
    },

    checkNode(node, err) {
      if (node.type === 'CONDITIONS') {
        node.branchs.map(item => {
          if (!item.children?.id) {
            // 条件分支节点没有字节点了
            // console.log('777777', node)
            if (node.children.children?.type === 'CONDITIONS') {
              this.checkNode(node.children.children, err)
            } else if (node.children.children?.type === 'APPROVAL') {
              return
            } else {
              // console.log('222',err);
              const errTitle = err.some(it => it === '每条分支上至少设置一个审批节点')
              if (errTitle) return
              err.push('每条分支上至少设置一个审批节点')
            }
          } else {
            this.checkNode(item.children, err)
          }
        })
      } else {
        if (node.children.id) this.checkNode(node.children, err)
      }
    }
  },

  watch: {}
}
</script>

<style lang="less">
._root {
  margin: 0 auto;
}
.process-end {
  width: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: small;
  color: #747474;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px 0 #bcbcbc;
}
.primary-node {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.branch-node {
  display: flex;
  justify-content: center;
}
.branch-node-item {
  position: relative;
  display: flex;
  // background: #f5f6f6;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    margin: auto;
    width: 1px;
    height: 100%;
    background-color: #cacaca;
  }
  .line-top-left,
  .line-top-right,
  .line-bot-left,
  .line-bot-right {
    position: absolute;
    width: 50%;
    height: 4px;
    background-color: #f0f2f5;
  }
  .line-top-left {
    top: -2px;
    left: -1px;
  }
  .line-top-right {
    top: -2px;
    right: -1px;
  }
  .line-bot-left {
    bottom: -2px;
    left: -1px;
  }
  .line-bot-right {
    bottom: -2px;
    right: -1px;
  }
}
.add-branch-btn {
  position: absolute;
  width: 120px;
  .add-branch-btn-el {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 4px 10px;
    background-color: #fff;
    z-index: 999;
    position: absolute;
    top: -15px;
    left: 20px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }

  .btn-disabled {
    cursor: no-drop;
    background: #f5f5f5;
    // color: ;
    color: rgba(0, 0, 0, 0.25);
  }
}

.empty-node {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
